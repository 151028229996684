import React from 'react'
import PropTypes from 'prop-types'
/* Ref: https://github.com/google-map-react/google-map-react/blob/master/API.md */
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    const {
      data: { content, cta_text, cta_link, marker, options, key },
    } = props

    const styles = options.options_styles
      ? require('./Styles/' + options.options_styles)
      : []
    const behavior = options.options_behavior ? options.options_behavior : ''
    const controls = options.options_controls ? options.options_controls : ''

    this.MAP = {
      content: content,
      ctaText: cta_text,
      ctaLink: cta_link,
      defaultZoom: options.options_defaultzoom
        ? Number(options.options_defaultzoom)
        : 16,
      defaultCenter: marker
        ? {
            lat: centerItems('lat', marker),
            lng: centerItems('lng', marker),
          }
        : {
            lat: 49.86854,
            lng: 8.92726,
          },
      options: {
        styles: styles,
        maxZoom: Number(options.options_maxzoom),
        minZoom: Number(options.options_minzoom),
        minZoomOverride: options.options_minzoom ? true : false,
        /* MAP BEHAVIOR */
        scrollwheel: behavior.includes('scrollwheel'),
        draggable: behavior.includes('draggable'),
        keyboardShortcuts: behavior.includes('keyboardShortcuts'),
        disableDoubleClickZoom: behavior.includes('disableDoubleClickZoom'),
        /* CONTROLS */
        zoomControl: controls.includes('zoomControl'),
        panControl: controls.includes('panControl'),
        mapTypeControl: controls.includes('mapTypeControl'),
        scaleControl: controls.includes('scaleControl'),
        streetViewControl: controls.includes('streetViewControl'),
        rotateControl: controls.includes('rotateControl'),
        fullscreenControl: controls.includes('fullscreenControl'),
      },
      key: key,
    }
  }

  render() {
    const { MAP } = this
    const { data: { marker } } = this.props

    const target = MAP.ctaLink.target ? MAP.ctaLink.target : null

    return (
      <div className="row dhsv_googlemap google-map">
        <div className="col-md-6">
          <div
            className="google-map__content"
            dangerouslySetInnerHTML={{ __html: MAP.content }}
          />
        </div>
        <div className="col-md-6">
          <div className="google-map__wrapper">
            <div className="google-map__map">
              <GoogleMapReact
                ref={e => {
                  this.map = e
                }}
                defaultZoom={MAP.defaultZoom}
                defaultCenter={MAP.defaultCenter}
                options={MAP.options}
                onChange={this.handleMapChange}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: MAP.key }}
              >
                {marker
                  ? marker.map((place, key) => (
                      <Marker key={key} lat={place.lat} lng={place.lng} />
                    ))
                  : null}
              </GoogleMapReact>
            </div>
            {MAP.ctaText || Map.ctaLink.url ? (
              <a
                className="google-map__cta"
                href={MAP.ctaLink.url}
                target={target}
              >
                <p>{MAP.ctaText}</p>
                <div className="btn-wrapper">
                  <span className="btn btn-link">{MAP.ctaLink.title}</span>
                </div>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

function centerItems(index, array) {
  const sum = array
    .map(item => Number(item[index]))
    .reduce((pre, cur) => (cur += pre))
  return Number(sum / array.length)
}

GoogleMap.propTypes = {
  data: PropTypes.object.isRequired,
  overlay: PropTypes.object,
}

export default GoogleMap
