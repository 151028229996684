import $ from 'jquery'
import '@fancyapps/fancybox'

$(document).ready(function() {
  dhsv_vc_planning_content()
})

const dhsv_vc_planning_content = () => {
  $('.dhsv_vc_planning-content').each(function() {})
}
