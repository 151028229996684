import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_teaser_boxes()
})

function dhsv_vc_teaser_boxes() {
  var counter = 0

  //first six elements
  $('.dhsv_vc_teaser_boxes .teaser-box').each(function() {
    counter++
    var first_six_elements = $(this)
      .parent()
      .hasClass('hide')
    if (counter <= 6) {
      if (first_six_elements) {
        $(this)
          .parent()
          .removeClass('hide')
        $(this)
          .parent()
          .addClass('show')
      }
    }
  })

  // eslint-disable-next-line no-console
  //console.log(counter)

  $('#show_all ').on('click tap', function() {
    // eslint-disable-next-line no-console
    //console.log('Button Clicked')

    $('.dhsv_vc_teaser_boxes .teaser-box').each(function() {
      var parent = $(this).parent()
      if (parent.hasClass('hide')) {
        $(this)
          .parent()
          .removeClass('hide')
        $(this)
          .parent()
          .addClass('show')
      } else {
        $('#show_all ').css('display', 'none')
      }
    })
  })
}
