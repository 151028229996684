import $ from 'jquery'
import '@fancyapps/fancybox'

$(document).ready(function() {
  dhsv_vc_overview()
})

const dhsv_vc_overview = () => {
  $('.dhsv_vc_overview').each(function() {})
}
