import cssVars from 'css-vars-ponyfill'

function resizeFullWidthRow() {
  $('[data-vc-full-width="true"]').each((i, el) => {
    $(el).css({
      width: document.body.clientWidth,
      marginLeft: -document.body.clientWidth / 2,
    })
  })
}

function fullHeightIntro() {
  if ($('.banners_slider').length > 0) {
    $('.dhsv_vc_intro_section').addClass('nofull-active')
  } else {
    $('.dhsv_vc_intro_section').addClass('full-active')
  }
}

function headerTitle() {
  $('.header-section__title').each(function() {
    let text = $(this)
      .text()
      .split(' ')

    for (var i = 0, len = text.length; i < len; i++) {
      if (i == 1 || i == 5) {
        text[i] = '<span class="word-' + i + '">' + text[i] + '</span><br>'
      } else {
        text[i] = '<span class="word-' + i + '">' + text[i] + '</span>'
      }
    }
    $(this).html(text.join(' '))
  })

  $('.header-section__title_variant').each(function() {
    let text = $(this)
      .text()
      .split(' ')

    for (var i = 0, len = text.length; i < len; i++) {
      if (i == 2 || i == 5) {
        text[i] = '<span class="word-' + i + '">' + text[i] + '</span><br>'
      } else {
        text[i] = '<span class="word-' + i + '">' + text[i] + '</span>'
      }
    }
    $(this).html(text.join(' '))
  })
}

const setBodyWidth = () => {
  cssVars({
    variables: { '--body-width': document.body.clientWidth + 'px' },
  })
}

function langShowToggler() {
  $('.js-language-switcher').click(function() {
    $(this).toggleClass('toggled')
    if ($(this).next('.language-list:visible').length) {
      $('.language-list')
        .slideUp()
        .removeClass('active')
    } else {
      $('.language-list')
        .slideDown({
          start: function() {
            $(this).css('display', 'flex')
          },
        })
        .addClass('active')
    }
  })
}

const resizeMaking = func => {
  let resizeMaking
  $(window).resize(() => {
    clearTimeout(resizeMaking)
    resizeMaking = setTimeout(function() {
      func()
    }, 100)
  })
}

const showMenuOnResize = () => {
  const showFunction = () => {
    if ($(window).width() > 1199) {
      $('.nav-primary .nav').css('display', 'flex')
    }
  }

  resizeMaking(showFunction)
}

let Menu = {
  el: {
    ham: $('.menu-icon'),
    menuTop: $('.menu-top'),
    menuMiddle: $('.menu-middle'),
    menuBottom: $('.menu-bottom'),
  },

  init: function() {
    Menu.bindUIactions()
  },

  bindUIactions: function() {
    Menu.el.ham.on('click', function(event) {
      Menu.activateMenu(event)
      event.preventDefault()
      $('.banner ul.nav').fadeToggle()
    })
  },

  activateMenu: function() {
    Menu.el.menuTop.toggleClass('menu-top-click')
    Menu.el.menuMiddle.toggleClass('menu-middle-click')
    Menu.el.menuBottom.toggleClass('menu-bottom-click')
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    resizeFullWidthRow()

    fullHeightIntro()

    setTimeout(headerTitle, 3000)

    setBodyWidth()

    langShowToggler()

    showMenuOnResize()

    // google ads conversion tracking
    // now directly

    // #1 button clicks
    // $(document).on('click', '.dhsv_vc_button > a', function() {
    //   var href = $(this).attr('href')
    //   if (href == '#online-meeting') {
    //     // eslint-disable-next-line
    //     gtag('event', 'conversion', {
    //       send_to: '5I5yCMrd3ocYEOfH3ZYD',
    //     })
    //     // eslint-disable-next-line
    //     //console.log(href)
    //   }
    //   if (href == 'tel:+496106669750') {
    //     // eslint-disable-next-line
    //     gtag('event', 'conversion', {
    //       send_to: 'FUI2CJDg4YcYEOfH3ZYD',
    //     })
    //     // eslint-disable-next-line
    //     //console.log(href)
    //   }
    // })
    //
    // // #2 footer links clicks
    // $(document).on('click', 'footer a', function() {
    //   var href = $(this).attr('href')
    //   if (href == 'mailto:hello@stadler-systec.com') {
    //     // eslint-disable-next-line
    //     gtag('event', 'conversion', {
    //       send_to: 'IyuYCJPg4YcYEOfH3ZYD',
    //     })
    //     // eslint-disable-next-line
    //     //console.log(href)
    //   }
    //   if (href == 'tel:+496106669750') {
    //     // eslint-disable-next-line
    //     gtag('event', 'conversion', {
    //       send_to: 'FUI2CJDg4YcYEOfH3ZYD',
    //     })
    //     // eslint-disable-next-line
    //     //console.log(href)
    //   }
    // })

    $("a[href='#top']").click(function() {
      $('html, body').animate({ scrollTop: 0 }, 'slow')
      return false
    })

    $(window).scroll(function() {
      if ($(document).scrollTop() > 100) {
        $('header.banner').addClass('header-active')
      } else {
        $('header.banner').removeClass('header-active')
      }

      let win = $(window)
      let winH = win.height()

      if ($(document).scrollTop() > winH) {
        $('.scroll-top').addClass('active-scroll-top')
      } else {
        $('.scroll-top').removeClass('active-scroll-top')
      }
    })

    $('.dropdown-toggle-js').on('click', function() {
      $(this)
        .toggleClass('active')
        .next()
        .fadeToggle()
    })

    Menu.init()

    $(window).on('resize', () => {
      resizeFullWidthRow()
      setBodyWidth()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
