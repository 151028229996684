import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_popup_form()
})

function dhsv_vc_popup_form() {
  $('.dhsv_vc_popup_form').each(function() {
    const id = $(this).attr('id')
    const elem = $(this)

    if (id) {
      $(document).on('click', `a[href='#${id}']`, () => {
        $(`#${id}`).fadeIn()
      })
    }

    $(document).on('click', '.dhsv_vc_popup_form__close_button', () => {
      elem.fadeOut()
    })

    $(this).on('click', e => {
      const $gform = $(this).find('.gform_wrapper')
      if (!$gform.is(e.target) && $gform.has(e.target).length === 0) {
        $(this).fadeOut()
      }
    })
  })
}
