import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  dhsv_vc_solutions()
})

const dhsv_vc_solutions = () => {
  $('.dhsv_vc_solutions').each(function() {
    if ($(window).width() > 575) {
      $('.js-solution').each(function() {
        let highestBox
        if ($('.js-cta', this).length > 1) {
          highestBox = 0
        }
        $('.js-cta', this).each(function() {
          if ($(this).height() > highestBox) {
            highestBox = $(this).height()
          }
        })
        $('.js-cta', this).height(highestBox)
      })
    }
  })

  $('.solution-slider').slick({
    rows: 2,
    dots: true,
    appendDots: $('.custom-dots'),
    prevArrow: $('.custom-prev'),
    nextArrow: $('.custom-next'),
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })
}
